<template>
    <div class="container">
        <div class="row pt-5 pb-3">
            <p class="col-12 text-center text-md-start col-md-6 text-body-secondary p-0 m-0">© {{year}} Copyright by My Gita GPT</p>

            <p class="col-12 text-center text-md-end col-md-6 text-body-secondary p-0 m-0">Design Developed by 9x Technology</p>
        </div>
    </div>
</template>
<script>
    export default
    {
        name: "FooterVue",
        data() {
            return {
                year: new Date().getFullYear()
            }
        }
    }
</script>